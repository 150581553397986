<template>
    <div :class="[`relative flex w-full flex-col`]">
        <div
            :class="[
                'relative flex h-input w-full flex-col items-start justify-start overflow-hidden rounded-lg border px-4 py-3 font-400 text-base',
                errorMessage ? '!border-red-700' : '',
                isFocused ? `border-neutral-1000` : `border-neutral-200`,
                wrapperClass,
                $attrs.disabled ? `bg-neutral-100` : ``,
            ]"
        >
            <AInputLabel
                v-if="label"
                :for="$attrs.id"
                :class="[
                    inputValue?.length && !errorMessage
                        ? `text-neutral-600`
                        : ``,
                ]"
            >
                <div class="flex flex-row items-center gap-1">
                    {{ label }}
                    <div
                        v-if="labelIcon"
                        class="flex size-4 items-center justify-center rounded-full bg-[#65CF70] text-neutral-50"
                    >
                        <component :is="labelIcon" class="size-2.5" />
                    </div>
                </div>
            </AInputLabel>
            <div class="flex w-full flex-1 flex-row items-center gap-2">
                <component
                    :is="leftComponent"
                    class="relative"
                    v-bind="leftComponentProps"
                />
                <input
                    ref="$el"
                    v-bind="$attrs"
                    v-model="inputValue"
                    :class="[
                        'w-10 flex-1 bg-transparent text-base text-neutral-1000 outline-none placeholder:text-neutral-400',
                    ]"
                />
            </div>
        </div>
        <div
            v-if="!!errorMessage"
            class="absolute inset-x-0 bottom-0 translate-y-full pt-1 text-xs text-red-700"
        >
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useElementHover, useFocus } from '@vueuse/core'
import { type Component, computed, type InputHTMLAttributes, ref } from 'vue'

interface AInputProps extends /** @vue-ignore */ InputHTMLAttributes {
    wrapperClass?: string
    label?: string
    errorMessage?: string
    leftComponent?: Component
    leftComponentProps?: Record<string, any>
    labelIcon?: Component // New prop for the icon
}

defineProps<AInputProps>()
defineOptions({ inheritAttrs: false })

const inputValue = defineModel<string>()

const $el = ref<HTMLInputElement>()

const isHovered = useElementHover($el)
const { focused: isFocused } = useFocus($el)

const _isHoveredOrFocused = computed(() => isHovered.value || isFocused.value)

defineExpose({ $el })
</script>
